<template>
    <h4>Reusable CSS widgets for your applications.</h4>
	<div class="grid">
		<div class="col-12">
			<div class="card">
				<h4>Overview Boxes</h4>
				<div class="grid">
					<div class="col-12 md:col-6 lg:col-3">
						<div class="widget-overview overview-box-1">
							<div class="overview-box-title">
								<i class="pi pi-inbox"></i>
								<span>Unread Messages</span>
							</div>
							<div class="overview-box-count">150</div>
							<div class="overview-box-stats">12 less than yesterday</div>
						</div>
					</div>
					<div class="col-12 md:col-6 lg:col-3">
						<div class="widget-overview overview-box-2">
							<div class="overview-box-title">
								<i class="pi pi-map-marker"></i>
								<span>Check-ins</span>
							</div>
							<div class="overview-box-count">532</div>
							<div class="overview-box-stats">46 more than yesterday</div>
						</div>
					</div>
					<div class="col-12 md:col-6 lg:col-3">
						<div class="widget-overview overview-box-3">
							<div class="overview-box-title">
								<i class="pi pi-folder"></i>
								<span>Files</span>
							</div>
							<div class="overview-box-count">450</div>
							<div class="overview-box-stats">30 more than yesterday</div>
						</div>
					</div>
					<div class="col-12 md:col-6 lg:col-3">
						<div class="widget-overview overview-box-4">
							<div class="overview-box-title">
								<i class="pi pi-user"></i>
								<span>Users</span>
							</div>
							<div class="overview-box-count">532</div>
							<div class="overview-box-stats">250 more than yesterday</div>
						</div>
					</div>
				</div>
			</div>
		</div>

        <div class="col-12 lg:col-6">
            <div class="card widget-graph">
                <h5>Weekly Overview</h5>

                <Chart type="line" :data="chartData" :options="chartOptions"/>
            </div>
        </div>

        <div class="col-12 lg:col-6">
            <div class="card widget-goals">
                <div class="flex align-items-center justify-content-between mb-4">
                    <h5>Quarter Goals</h5>
                    <div class="ml-auto">
                        <Button type="button" icon="pi pi-ellipsis-v" class="p-button-text p-button-plain p-button-rounded" @click="toggle" />
                        <Menu ref="menu" :model="items" :popup="true" />
                    </div>
                </div>
                <div class="goals-progress p-3 mb-4">
                    <span class="goals-progress-title">85% <span class="goals-progress-subtitle">(2125/2500)</span></span>
                    <ul class="mt-3 p-0 mx-0 flex">
                        <li style="height:1rem" class="goals-progress-cyan"/>
                        <li style="height:1rem" class="goals-progress-orange"/>
                        <li style="height:1rem" class="goals-progress-pink"/>
                        <li style="height:1rem" class="goals-progress-purple"/>
                        <li style="height:1rem" class="goals-progress-blue"/>
                        <li style="height:1rem" class="goals-progress-gray"/>
                    </ul>
                </div>

                <ul class="mt-4 p-0 mx-0">
                    <li class="flex align-items-center py-3 goals-list-item">
                        <span style="width: 1rem; height: 1rem" class="goals-list-cyan mr-3"/>
                        <span class="goals-list-title">T-Shirt</span>
                        <span class="goals-list-value ml-auto">89</span>
                    </li>
                    <li class="flex align-items-center py-3 goals-list-item">
                        <span style="width: 1rem; height: 1rem" class="goals-list-orange mr-3"/>
                        <span class="goals-list-title">Controller</span>
                        <span class="goals-list-value ml-auto">23</span>
                    </li>
                    <li class="flex align-items-center py-3 goals-list-item">
                        <span style="width: 1rem; height: 1rem" class="goals-list-pink mr-3"/>
                        <span class="goals-list-title">Phone Case</span>
                        <span class="goals-list-value ml-auto">134</span>
                    </li>
                    <li class="flex align-items-center py-3 goals-list-item">
                        <span style="width: 1rem; height: 1rem" class="goals-list-purple mr-3"/>
                        <span class="goals-list-title">Purple Band</span>
                        <span class="goals-list-value ml-auto">42</span>
                    </li>
                    <li class="flex align-items-center py-3 goals-list-item">
                        <span style="width: 1rem; height: 1rem" class="goals-list-blue mr-3"/>
                        <span class="goals-list-title">Blue Band</span>
                        <span class="goals-list-value ml-auto">63</span>
                    </li>
                </ul>
            </div>
        </div>

        <div class="col-12 lg:col-4 p-3">
            <div class="widget-colored-stats card">
                <div class="flex align-items-center justify-content-between">
                    <div>
                        <span class="stats-value">450</span>
                        <p class="mt-2 mb-0 stats-title">Reviews Received</p>
                    </div>
                    <div>
                        <img src="layout/images/dashboard/stats-illustration-1.svg" alt="stats-illustration-1"/>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 lg:col-4 p-3">
            <div class="widget-colored-stats card">
                <div class="flex align-items-center justify-content-between">
                    <div>
                        <span class="stats-value">71K</span>
                        <p class="mt-2 mb-0 stats-title">Unique Visitors</p>
                    </div>
                    <div>
                        <img src="layout/images/dashboard/stats-illustration-2.svg" alt="stats-illustration-2"/>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 lg:col-4 p-3">
            <div class="widget-colored-stats card">
                <div class="flex align-items-center justify-content-between">
                    <div>
                        <span class="stats-value">757</span>
                        <p class="mt-2 mb-0 stats-title">Payments Processed</p>
                    </div>
                    <div>
                        <img src="layout/images/dashboard/stats-illustration-3.svg" alt="stats-illustration-3"/>
                    </div>
                </div>
            </div>
        </div>

        <div class='col-12 lg:col-8'>
            <div class='card widget-incard p-4'>
                <div class='flex align-items-center justify-content-between mb-4'>
                    <h5>Files</h5>
                    <Button icon='pi pi-refresh' class='p-button-rounded p-button-outlined' />
                </div>

                <div class='grid mr-0'>
                    <div class='col-12 sm:col-6 md:col-4 lg:col-3 xl:col-2 p-3'>
                        <div class='text-center incard-item'>
                            <div class='flex align-items-center justify-content-center mb-3 mx-auto incard-image'
                                style='width: 90px; height: 90px'>
                                <img src='layout/images/dashboard/word.svg' alt='avalon'
                                    style='width: 48px; height: 48px' />
                            </div>
                            <span class='incard-title'>resume.docx</span>
                            <div class='incard-size mt-2'>520KB</div>
                        </div>
                    </div>
                    <div class='col-12 sm:col-6 md:col-4 lg:col-3 xl:col-2 p-3'>
                        <div class='text-center incard-item'>
                            <div class='flex align-items-center justify-content-center mb-3 mx-auto incard-image'
                                style='width: 90px; height: 90px'>
                                <img src='layout/images/dashboard/excel.svg' alt='avalon'
                                    style='width: 48px; height: 48px' />
                            </div>
                            <span class='incard-title'>customers.xlsx</span>
                            <div class='incard-size mt-2'>120KB</div>
                        </div>
                    </div>
                    <div class='col-12 sm:col-6 md:col-4 lg:col-3 xl:col-2 p-3'>
                        <div class='text-center incard-item'>
                            <div class='flex align-items-center justify-content-center mb-3 mx-auto incard-image'
                                style='width: 90px; height: 90px'>
                                <img src='layout/images/dashboard/excel.svg' alt='avalon'
                                    style='width: 48px; height: 48px' />
                            </div>
                            <span class='incard-title'>stats.xlsx</span>
                            <div class='incard-size mt-2'>500KB</div>
                        </div>
                    </div>
                    <div class='col-12 sm:col-6 md:col-4 lg:col-3 xl:col-2 p-3'>
                        <div class='text-center incard-item'>
                            <div class='flex align-items-center justify-content-center mb-3 mx-auto incard-image'
                                style='width: 90px; height: 90px'>
                                <img src='layout/images/dashboard/ps.svg' alt='avalon'
                                    style='width: 48px; height: 48px' />
                            </div>
                            <span class='incard-title'>mock.ps</span>
                            <div class='incard-size mt-2'>220KB</div>
                        </div>
                    </div>
                    <div class='col-12 sm:col-6 md:col-4 lg:col-3 xl:col-2 p-3'>
                        <div class='text-center incard-item'>
                            <div class='flex align-items-center justify-content-center mb-3 mx-auto incard-image'
                                style='width: 90px; height: 90px'>
                                <img src='layout/images/dashboard/atom.svg' alt='avalon'
                                    style='width: 48px; height: 48px' />
                            </div>
                            <span class='incard-title'>template.js</span>
                            <div class='incard-size mt-2'>10KB</div>
                        </div>
                    </div>
                    <div class='col-12 sm:col-6 md:col-4 lg:col-3 xl:col-2 p-3'>
                        <div class='text-center incard-item'>
                            <div class='flex align-items-center justify-content-center mb-3 mx-auto incard-image'
                                style='width: 90px; height: 90px'>
                                <img src='layout/images/dashboard/code.svg' alt='avalon'
                                    style='width: 48px; height: 48px' />
                            </div>
                            <span class='incard-title'>main.css</span>
                            <div class='incard-size mt-2'>10KB</div>
                        </div>
                    </div>
                    <div class='col-12 sm:col-6 md:col-4 lg:col-3 xl:col-2 p-3'>
                        <div class='text-center incard-item'>
                            <div class='flex align-items-center justify-content-center mb-3 mx-auto incard-image'
                                style='width: 90px; height: 90px'>
                                <img src='layout/images/dashboard/ai.svg' alt='avalon'
                                    style='width: 48px; height: 48px' />
                            </div>
                            <span class='incard-title'>poc.ai</span>
                            <div class='incard-size mt-2'>4.2MB</div>
                        </div>
                    </div>
                    <div class='col-12 sm:col-6 md:col-4 lg:col-3 xl:col-2 p-3'>
                        <div class='text-center incard-item'>
                            <div class='flex align-items-center justify-content-center mb-3 mx-auto incard-image'
                                style='width: 90px; height: 90px'>
                                <img src='layout/images/dashboard/word.svg' alt='avalon'
                                    style='width: 48px; height: 48px' />
                            </div>
                            <span class='incard-title'>sales.docx</span>
                            <div class='incard-size mt-2'>420KB</div>
                        </div>
                    </div>
                    <div class='col-12 sm:col-6 md:col-4 lg:col-3 xl:col-2 p-3'>
                        <div class='text-center incard-item'>
                            <div class='flex align-items-center justify-content-center mb-3 mx-auto incard-image'
                                style='width: 90px; height: 90px'>
                                <img src='layout/images/dashboard/word.svg' alt='avalon'
                                    style='width: 48px; height: 48px' />
                            </div>
                            <span class='incard-title'>mynotes.one</span>
                            <div class='incard-size mt-2'>60KB</div>
                        </div>
                    </div>
                    <div class='col-12 sm:col-6 md:col-4 lg:col-3 xl:col-2 p-3'>
                        <div class='text-center incard-item'>
                            <div class='flex align-items-center justify-content-center mb-3 mx-auto incard-image'
                                style='width: 90px; height: 90px'>
                                <img src='layout/images/dashboard/code.svg' alt='avalon'
                                    style='width: 48px; height: 48px' />
                            </div>
                            <span class='incard-title'>layout.css</span>
                            <div class='incard-size mt-2'>25KB</div>
                        </div>
                    </div>
                    <div class='col-12 sm:col-6 md:col-4 lg:col-3 xl:col-2 p-3'>
                        <div class='text-center incard-item'>
                            <div class='flex align-items-center justify-content-center mb-3 mx-auto incard-image'
                                style='width: 90px; height: 90px'>
                                <img src='layout/images/dashboard/word.svg' alt='avalon'
                                    style='width: 48px; height: 48px' />
                            </div>
                            <span class='incard-title'>reports.docx</span>
                            <div class='incard-size mt-2'>120KB</div>
                        </div>
                    </div>
                    <div class='col-12 sm:col-6 md:col-4 lg:col-3 xl:col-2 p-3'>
                        <div class='text-center incard-item'>
                            <div class='flex align-items-center justify-content-center mb-3 mx-auto incard-image'
                                style='width: 90px; height: 90px'>
                                <img src='layout/images/dashboard/ps.svg' alt='avalon'
                                    style='width: 48px; height: 48px' />
                            </div>
                            <span class='incard-title'>design.psd</span>
                            <div class='incard-size mt-2'>1.2MB</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 lg:col-4 widget-contacts">
            <div class="card">
                <h4>Contacts</h4>
                <ul>
                    <li class="clearfix">
                        <img alt="User" src="layout/images/avatar.png" width="45" />
                        <div class="contact-info">
                            <span class="name">Madison Hughes (me)</span>
                            <span class="location">jane@pn-avalon.com</span>
                        </div>
                        <div class="contact-actions">
                            <span class="connection-status online">online</span>
                            <i class="pi pi-fw pi-comment"></i>
                            <i class="pi pi-fw pi-reply"></i>
                        </div>
                    </li>
                    <li class="clearfix">
                        <img alt="Contact 1" src="layout/images/avatar1.png" width="45" />
                        <div class="contact-info">
                            <span class="name">Joshua Williams</span>
                            <span class="location">joshua@pn-avalon.com</span>
                        </div>
                        <div class="contact-actions">
                            <span class="connection-status online">online</span>
                            <i class="pi pi-fw pi-comment"></i>
                            <i class="pi pi-fw pi-reply"></i>
                        </div>
                    </li>
                    <li class="clearfix">
                        <img alt="Contact 2" src="layout/images/avatar2.png" width="45" />
                        <div class="contact-info">
                            <span class="name">Emily Clark</span>
                            <span class="location">emily@pn-avalon.com</span>
                        </div>
                        <div class="contact-actions">
                            <span class="connection-status offline">offline</span>
                            <i class="pi pi-fw pi-comment"></i>
                            <i class="pi pi-fw pi-reply"></i>
                        </div>
                    </li>
                    <li class="clearfix">
                        <img alt="Contact 3" src="layout/images/avatar3.png" width="45" />
                        <div class="contact-info">
                            <span class="name">Tim Johnson</span>
                            <span class="location">tim@pn-avalon.com</span>
                        </div>
                        <div class="contact-actions">
                            <span class="connection-status online">online</span>
                            <i class="pi pi-fw pi-comment"></i>
                            <i class="pi pi-fw pi-reply"></i>
                        </div>
                    </li>
                    <li class="clearfix">
                        <img alt="Contact 4" src="layout/images/avatar4.png" width="45" />
                        <div class="contact-info">
                            <span class="name">David Stark</span>
                            <span class="location">kelly@pn-avalon.com</span>
                        </div>
                        <div class="contact-actions">
                            <span class="connection-status offline">offline</span>
                            <i class="pi pi-fw pi-comment"></i>
                            <i class="pi pi-fw pi-reply"></i>
                        </div>
                    </li>
                </ul>
            </div>
        </div>

		<div class="col-12 lg:col-8">
			<div class="card widget-chat">
                <h5>Chat</h5>
				<ul>
					<li class="clearfix message-from">
						<img alt="Contact 2" src="layout/images/avatar2.png" />
						<span>Retro occupy organic, stumptown shabby chic pour-over roof party DIY normcore.</span>
					</li>
					<li class="clearfix message-own">
						<img alt="User" src="layout/images/avatar.png" />
						<span>Actually artisan organic occupy, Wes Anderson ugh whatever pour-over gastropub selvage.</span>
					</li>
					<li class="clearfix message-from">
						<img alt="Contact 2" src="layout/images/avatar2.png" />
						<span>Chillwave craft beer tote bag stumptown quinoa hashtag.</span>
					</li>
					<li class="clearfix message-own">
						<img alt="User" src="layout/images/avatar.png" />
						<span>Dreamcatcher locavore iPhone chillwave, occupy trust fund slow-carb distillery art party narwhal.</span>
					</li>
					<li class="clearfix message-own">
						<span>Sed ut perspiciatis unde omnis iste natus.</span>
					</li>
					<li class="clearfix message-from">
						<img alt="Contact 2" src="layout/images/avatar2.png" />
						<span>Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse.</span>
					</li>
					<li class="clearfix message-own">
						<img alt="User" src="layout/images/avatar.png" />
						<span>At vero eos et accusamus.</span>
					</li>
				</ul>
				<div class="new-message">
					<div class="message-attachment">
						<i class="pi pi-paperclip"></i>
					</div>
					<div class="message-input">
						<input type="text" placeholder="Write a message" class="p-inputtext" />
					</div>
				</div>
			</div>

			<div class="card widget-messages">
				<h5>Messages</h5>		
				<div class="flex-row">
					<div class="message-box">
					
						<!--1st message row-->		
						<div class="message">
							<img src="layout/images/dashboard-banking/profile.png" alt="avalon-layout" class="messager-img" />		
							<div class="messager">Joshua Williams
								<i class="pi pi-circle-on online"></i>
								<span class="date pull-right">Today</span>
							</div>		
							<div class="message-text">Paenitet me quod tu me rogas? Oh, sic, qui stultus plastic continentis rogavi te ut emas. Vides non manducare acidum hydrofluoric per plastic. </div>
						</div>		
						<div class="clearfix"></div>
					</div>		
					<div class="message-box">
						<!--2nd message row-->		
						<div class="message">
							<img src="layout/images/dashboard-banking/profile1.png" alt="avalon-layout" class="messager-img" />		
							<div class="messager">Adelle Charles
								<i class="pi pi-circle-on offline"></i>
								<span class="date pull-right">Today</span>
							</div>		
							<div class="message-text">Quinquaginta septem est - pars tua, triginta quinque millia. Est autem extra plus quindecim, tota tua est, quom meruisset.</div>
						</div>		
						<div class="clearfix"></div>
					</div>		
					<div class="message-box">
					
						<!--3rd message row-->		
						<div class="message">
							<img src="layout/images/dashboard-banking/profile2.png" alt="avalon-layout" class="messager-img" />		
							<div class="messager">Marcos Moralez
								<i class="pi pi-circle-on online"></i>
								<span class="date pull-right">Yesterday</span>
							</div>		
								<div class="message-text">Fac nos fecit. SIC.</div>
							</div>		
							<div class="clearfix"></div>
						</div>		
					<div class="message-box">
					
						<!--4th message row-->		
						<div class="message">
							<img src="layout/images/dashboard-banking/profile3.png" alt="avalon-layout" class="messager-img" />		
							<div class="messager">Matt Litherland
								<i class="pi pi-circle-on online"></i>
								<span class="date pull-right">3 days ago</span>
							</div>		
							<div class="message-text">Puto quia una res potest - venimus in cognitionem. Vide pretium in manibus.</div>
						</div>		
						<div class="clearfix"></div>
					</div>
				</div>
			</div>
		</div>

        <div class="col-12 lg:col-4">
            <div class="card widget-timeline p-fluid">
                <div class="grid">
                    <div class="col-3">
                        <span class="event-time">just now</span>
                        <i class="pi pi-globe" style="color:#3d8bfd"></i>
                    </div>
                    <div class="col-9">
                        <span class="event-owner" style="color:#3d8bfd">Katherine May</span>
                        <span class="event-text">Lorem ipsun dolor amet</span>
                        <div class="event-content">
                            <img alt="Bridge" src="layout/images/dashboard/bridge.png" width="100"/>
                        </div>
                    </div>

                    <div class="col-3">
                        <span class="event-time">12h ago</span>
                        <i class="pi pi-star" style="color:#fd9843"></i>
                    </div>
                    <div class="col-9">
                        <span class="event-owner" style="color:#fd9843">Brandon Santos</span>
                        <span class="event-text">Ab nobis, magnam sunt eum. Laudantium, repudiandae, similique!.</span>
                    </div>

                    <div class="col-3">
                        <span class="event-time">15h ago</span>
                        <i class="pi pi-comment" style="color:#8c68cd"></i>
                    </div>
                    <div class="col-9">
                        <span class="event-owner" style="color:#8c68cd">Stephan Ward</span>
                        <span class="event-text">Omnis veniam quibusdam ratione est repellat qui nam quisquam ab mollitia dolores ullam voluptates, similique, dignissimos.</span>
                    </div>

                    <div class="col-3">
                        <span class="event-time">2d ago</span>
                        <i class="pi pi-map-marker" style="color:#de5c9d"></i>
                    </div>
                    <div class="col-9">
                        <span class="event-owner" style="color:#de5c9d">Jason Smith</span>
                        <span class="event-text">Laudantium, repudiandae, similique!</span>
                        <div class="event-content">
                            <img alt="Map" src="layout/images/dashboard/map.png" />
                        </div>
                    </div>

                    <div class="col-3">
                        <span class="event-time">1w ago</span>
                        <i class="pi pi-heart" style="color:#3dd5f3"></i>
                    </div>
                    <div class="col-9">
                        <span class="event-owner" style="color:#3dd5f3">Kevin Cox</span>
                        <span class="event-text">Quibusdam ratione est repellat qui nam quisquam veniam quibusdam ratione.</span>
                    </div>

                    <div class="col-3">
                        <span class="event-time">2w ago</span>
                        <i class="pi pi-compass" style="color:#479f76"></i>
                    </div>
                    <div class="col-9">
                        <span class="event-owner" style="color:#479f76">Walter White</span>
                        <span class="event-text">I am the one who knocks!</span>
                    </div>

                    <div class="col-12">
                        <Button type="button" label="Refresh" icon="pi pi-refresh" class="rounded-btn raised-btn"></Button>
                    </div>
                </div>
            </div>
        </div>

	</div>
</template>

<script>
	export default {
		name: "Widgets",
		data() {
			return {
                chartData: {
                    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
                    datasets: [
                        {
                            label: 'New',
                            data: [11, 17, 30, 60, 88, 92],
                            backgroundColor: 'rgba(13, 202, 240, .2)',
                            borderColor: '#0dcaf0',
                            pointBackgroundColor: '#0dcaf0',
                            pointBorderColor: '#0dcaf0',
                            pointBorderWidth: 0,
                            pointStyle: 'line',
                            fill: false,
                            tension: .4
                        },
                        {
                            label: 'Completed',
                            data: [11, 19, 39, 59, 69, 71],
                            backgroundColor: 'rgba(253, 126, 20, .2)',
                            borderColor: '#fd7e14',
                            pointBackgroundColor: '#fd7e14',
                            pointBorderColor: '#fd7e14',
                            pointBorderWidth: 0,
                            pointStyle: 'line',
                            fill: false,
                            tension: .4
                        },
                        {
                            label: 'Canceled',
                            data: [11, 17, 21, 30, 47, 83],
                            backgroundColor: 'rgba(111, 66, 193, .2)',
                            borderColor: '#6f42c1',
                            pointBackgroundColor: '#6f42c1',
                            pointBorderColor: '#6f42c1',
                            pointBorderWidth: 0,
                            pointStyle: 'line',
                            fill: true,
                            tension: .4
                        }
                    ]
                },
                chartOptions: {
                    plugins: {
                        legend: {
                            fill: true
                        }
                    },
                    scales: {
                        y: {
                            max: 100,
                            min: 0,
                            ticks: {
                                color: '#A0A7B5'
                            }
                        },
                        x: {
                            grid: {
                                display: true,
                            },
                            ticks: {
                                color: '#A0A7B5',
                                beginAtZero: true,
                            }
                        }
                    }
                },
                items: [
                    {
                        label: 'Options',
                        items: [
                            {label: 'Add New', icon: 'pi pi-fw pi-plus'},
                            {label: 'Search', icon: 'pi pi-fw pi-search'}
                        ]
                    }
                ],
			}
		},
        mounted() {
            this.getGradient();
        },
        methods: {
            toggle(event) {
                this.$refs.menu.toggle(event);
            },
            getGradient() {
                let chart = document.getElementsByTagName('canvas')[0].getContext('2d');
                const gradientStroke1 = chart.createLinearGradient(100, 0, 500, 100);
                gradientStroke1.addColorStop(0, 'rgba(63, 213, 250, 0)');
                gradientStroke1.addColorStop(0.5, 'rgba(63, 213, 250, 1)');
                gradientStroke1.addColorStop(1, 'rgba(10, 162, 192, 1)');
                this.chartData.datasets[0].borderColor = gradientStroke1;

                const gradientStroke2 = chart.createLinearGradient(100, 0, 500, 100);
                gradientStroke2.addColorStop(0, 'rgba(253, 152, 67, 0)');
                gradientStroke2.addColorStop(0.5, 'rgba(253, 152, 67, 1)');
                gradientStroke2.addColorStop(1, 'rgba(202, 101, 16, 1)');
                this.chartData.datasets[1].borderColor = gradientStroke2;

                const gradientStroke3 = chart.createLinearGradient(100, 0, 500, 100);
                gradientStroke3.addColorStop(0, 'rgba(140, 104, 205, 0)');
                gradientStroke3.addColorStop(0.5, 'rgba(140, 104, 205, 1)');
                gradientStroke3.addColorStop(1, 'rgba(89, 53, 154, 1)');
                this.chartData.datasets[2].borderColor = gradientStroke3;

                const gradientFill = chart.createLinearGradient(0, 0, 500, 0);
                gradientFill.addColorStop(1, 'rgba(89, 53, 154, 0.34)');
                gradientFill.addColorStop(0, 'rgba(140, 104, 205, 0.2)');
                this.chartData.datasets[2].backgroundColor = gradientFill;
            }
        }
	}
</script>

<style scoped>

</style>
